type InvisibleFocusOptions = {
  preventScroll: boolean;
};

const invisibleFocus = (
  $element: HTMLElement,
  options: InvisibleFocusOptions = { preventScroll: true },
): void => {
  const onBlur = () => {
    $element.removeEventListener('blur', onBlur);
    $element.classList.remove('u-invisible-focus');
  };

  $element.addEventListener('blur', onBlur);
  $element.classList.add('u-invisible-focus');
  $element.focus(options);
};

export default invisibleFocus;
