import React, { useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import classNames from 'classnames';

import Icon from '../../0-particles/icon/Icon';
import Checkbox from '../../1-atoms/checkbox/Checkbox';
import useCheckboxes, {
  CheckboxesState,
} from '../../1-atoms/checkbox/useCheckboxes';
import Radio, { RadioValue } from '../../1-atoms/radio/Radio';
import Button from '../../1-atoms/button/Button';

export type Option = {
  label: string;
  value: string;
  selected: boolean;
};

type Props = {
  multi?: boolean;
  label: string;
  name: string;
  options: Option[];
  isResetable?: boolean;
};

const EnhancedSelect: React.FC<Props> = ({
  multi,
  label,
  options,
  isResetable,
  name,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [
    checkboxesState,
    handleCheckboxChange,
    setCheckboxesState,
  ] = useCheckboxes();
  const [radioState, setRadioState] = React.useState<RadioValue>('');

  useEffect(() => {
    const initialCheckboxesState = options.reduce<CheckboxesState>(
      (acc: CheckboxesState, option: Option): CheckboxesState => {
        if (option.selected) {
          return {
            ...acc,
            [option.value]: true,
          };
        }
        return acc;
      },
      {},
    );

    setCheckboxesState(initialCheckboxesState);
  }, [options, setCheckboxesState]);

  useEffect(() => {
    const initialRadioState =
      options.find((option) => option.selected === true)?.value || '';

    setRadioState(initialRadioState);
  }, [options, setRadioState]);

  const toggleInput = () => {
    setIsOpen((o) => !o);
  };

  const handleRadioChange = (value: RadioValue) => {
    setRadioState(value);
  };

  const handleSubmit = (e) => {
    setIsOpen(false);
    e.target.closest('form').submit();
  };

  const handleReset = () => {
    setRadioState('');
    setCheckboxesState({});
  };

  const getLabel = () => {
    if (multi) {
      const checkboxesStateLength = Object.keys(checkboxesState).length;

      return checkboxesStateLength
        ? `${label}: ${checkboxesStateLength}`
        : label;
    }

    return (
      options.find((option) => option.value === radioState)?.label || label
    );
  };

  return (
    <>
      <div className="input enhanced-select">
        <div className="input__inner">
          <button
            className={classNames({
              input__input: true,
              'enhanced-select__input--open': isOpen,
            })}
            onClick={toggleInput}
            type="button"
          >
            {getLabel()}
          </button>

          <div className="input__icon">
            <Icon icon={isOpen ? 'caret-up' : 'caret-down'} />
          </div>
        </div>

        {isOpen && (
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              onDeactivate: () => setIsOpen(false),
            }}
          >
            <div
              className={classNames({
                'enhanced-select__menu': true,
                'enhanced-select__menu--open': true,
              })}
            >
              <ul>
                {options.map((option) => (
                  <li className="enhanced-select__list-item" key={option.value}>
                    {multi ? (
                      <Checkbox
                        label={option.label}
                        id={`checkbox-${option.value}`}
                        name={option.value}
                        value={option.value}
                        checked={checkboxesState[option.value]}
                        onChange={handleCheckboxChange}
                      />
                    ) : (
                      <Radio
                        label={option.label}
                        value={option.value}
                        checked={option.value === radioState}
                        onChange={handleRadioChange}
                        name="radio"
                        id={`radio-${option.value}`}
                      />
                    )}
                  </li>
                ))}
              </ul>

              {isResetable && (
                <div className="enhanced-select__buttons-wrap">
                  <Button
                    text="Zurücksetzen"
                    color="transparent"
                    onClick={handleReset}
                  />

                  <Button
                    text="Speichern"
                    color="black"
                    onClick={(e) => handleSubmit(e)}
                  />
                </div>
              )}
            </div>
          </FocusTrap>
        )}
      </div>

      {multi ? (
        Object.keys(checkboxesState).map((value) => (
          <input hidden key={value} name={name} value={value} readOnly />
        ))
      ) : (
        <input hidden name={name} value={radioState} readOnly />
      )}
    </>
  );
};

export default EnhancedSelect;
