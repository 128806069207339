import React from 'react';
import { render } from 'react-dom';

import whenReady from '../../../javascripts/utils/when-ready';
import wrap from '../../../javascripts/utils/wrap';
import Autocomplete from './Autocomplete';

const AutocompleteInit = () => {
  const $$autocomplete = document.querySelectorAll<HTMLElement>(
    '.autocomplete-js',
  );

  $$autocomplete.forEach(($autocomplete) => {
    const $input = $autocomplete.querySelector<HTMLInputElement>(
      '.input__input',
    );

    if (!$input) {
      return;
    }

    const $label = $autocomplete.querySelector<HTMLLabelElement>('label');

    const {
      endpoint,
      initialEndpoint,
      menutext,
      prefix,
      itemId,
    } = $autocomplete.dataset;

    if (!endpoint) {
      return;
    }

    const $wrapper = wrap($autocomplete);

    render(
      <Autocomplete
        label={$label?.textContent || null}
        placeholder={$input.placeholder || null}
        name={$input.name}
        value={$input.value}
        initialEndpoint={initialEndpoint}
        menuText={menutext}
        endpoint={endpoint}
        prefix={prefix}
        itemId={itemId}
      />,
      $wrapper,
    );
  });
};

whenReady().then(() => AutocompleteInit());

window.sdgAppAutocompleteInit = () => {
  AutocompleteInit();
};
