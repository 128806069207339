import * as d3 from 'd3';
import getTrend from '../../../javascripts/utils/get-trend';
import roundNumber from '../../../javascripts/utils/round-number';

export default class LineChart {
  constructor($el, data) {
    this.$el = $el;
    this.avgExists = false;
    this.data = data;
    this.classes = {
      axis: 'chart__axis',
      line: 'chart__line',
      circle: 'chart__circle',
      triangleGroup: 'chart__triangle-group',
      triangle: 'chart__triangle',
      lineLabel: 'chart__line-label',
    };
    this.colors = ['#3199ac', '#b17f49', '#000'];
    this.tooltip = d3.select(this.$el.querySelector('.chart__tooltip'));
    this.$yAxisSticky = this.$el.querySelector('.chart__axis--y');
    this.$scrollContainer = this.$el.querySelector('.chart__inner');

    this.init();
  }

  init() {
    if (this.data) {
      if (this.data.graphs) {
        if (this.data.graphs[0]['points']) {
          this.data.graphs.forEach((graph) => {
            if(graph.config.isAvg) {
              this.avgExists = true;
            }
          });
          this.setDimensions();
          this.setAxis();
          this.drawLines();
          this.drawCircles();
          this.addEvents();
          this.addLabels();
        }
      }
    }

    if (this.$scrollContainer && this.data.graphs[0]['points']) {
      this.$scrollContainer.scrollLeft = this.dimensions.width;
    }
  }

  setDimensions() {

    this.years = this.data.graphs
      .map((g) => g.points.map((p) => p[0]))
      .flat()
      .filter((v, i, a) => a.indexOf(v) === i);

    this.yValues = this.data.graphs
      .map((g) => g.points.map((p) => p[1]))
      .flat();

    let marginLeftCalc = (Math.round(Math.max(...this.yValues)).toString().length - 3) * 15;
    marginLeftCalc = marginLeftCalc > 0 ? marginLeftCalc + 30 : 30;
    
    const margin = { top: 30, right: 30, bottom: 30, left: marginLeftCalc };

    this.yearsPerWidth = this.years.length <= 10 ? this.years.length : 10;

    const parentWidth = this.$el.clientWidth;
    const width = (parentWidth / this.yearsPerWidth) * this.years.length;

    this.dimensions = {
      width: width - margin.left - margin.right,
      height: 190 - margin.top - margin.bottom,
      margin,
    };

    this.svg = d3
      .select(this.$el.querySelector('.chart__svg'))
      .append('svg')
      .attr('width', this.dimensions.width + margin.left + margin.right)
      .attr('height', this.dimensions.height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
  }

  setAxis() {
    const { graphs } = this.data;
    const yPercentMargin = 10;

    if (graphs) {
      this.xScale = d3
        .scaleLinear()
        .domain(d3.extent(this.years))
        .range([0, this.dimensions.width]);

      this.yExtent = d3.extent(this.yValues);
      this.yRange = this.yExtent[1] - this.yExtent[0];

      this.yScale = d3
        .scaleLinear()
        .domain([this.yExtent[1] + (this.yRange * (yPercentMargin/100)), this.yExtent[0] - (this.yRange * (yPercentMargin/100))])
        .range([0, this.dimensions.height]);

      const yearsWithoutFirst = this.years;
      //yearsWithoutFirst.shift();

      const yaxis = d3
        .axisLeft()
        .scale(this.yScale)
        .ticks(2);
      const xaxis = d3
        .axisBottom()
        .scale(this.xScale)
        .tickFormat(d3.format('.4'))
        .tickValues(yearsWithoutFirst);

      this.svg
        .append('g')
        .attr('class', this.classes.axis)
        .attr('transform', `translate(0,${this.dimensions.height})`)
        .call(xaxis);

      d3.select(this.$yAxisSticky)
        .append('svg')
        .attr('width', this.dimensions.margin.left + 2)
        .attr('height', this.dimensions.height + this.dimensions.margin.top * 2)
        .append('g')
        .attr('class', this.classes.axis)
        .attr(
          'transform',
          `translate(${this.dimensions.margin.left},${this.dimensions.margin.top})`,
        )
        .call(yaxis);
    }
  }

  drawLines() {
    this.line = d3
      .line()
      .x((d) => this.xScale(d[0]))
      .y((d) => this.yScale(d[1]));

    this.lines = this.svg
      .selectAll('lines')
      .data(this.data.graphs)
      .enter()
      .append('g');

    // draw lines
    this.lines
      .append('path')
      .attr(
        'class',
        (d) =>
          `${this.classes.line}${
            d.config.isAvg ? ` ${this.classes.line}--avg` : ''
          }`,
      )
      .attr('d', (d) => this.line(d.points));
  }

  drawCircles() {
    const circleClasses = (d, i) => {
      if (d.config.isAvg) {
        return `${this.classes.circle}--avg`;
      }
      return `${this.classes.circle}--${String.fromCharCode(
        'a'.charCodeAt(0) + (this.avgExists ? (i === 0 ? i : i - 1) : i),
      )}`;
    };

    this.lines
      .selectAll('pointGroup')
      .data(this.data.graphs)
      .enter()
      .append('g')
      .attr('class', circleClasses)
      .selectAll('point')
      .data((d) =>
        // filter out circles directly on the y axis
        d.points.filter((point) => point[0]),
      )
      .enter()
      .append('circle')
      .attr('class', this.classes.circle)
      .attr('r', 5.5)
      .attr('cx', (d) => {
        return this.xScale(d[0]);
      })
      .attr('cy', (d) => {
        return this.yScale(d[1]);
      });
  }

  addEvents() {
    this.tipBox = this.svg
      .append('rect')
      .attr('width', this.dimensions.width)
      .attr('height', this.dimensions.height)
      .attr('opacity', 0)
      .attr('class', 'chart__hover')
      .on('mousemove', this.mouseMove)
      .on('mouseout', this.mouseOut);
  }

  mouseMove = (event) => {
    this.drawTooltip(event);

    if (this.data.graphs.length < 3) {
      this.drawTrend(event);
    }
  };

  mouseOut = (event) => {
    this.removeTooltip(event);
    this.removeTrend(event);
  };

  drawTooltip = (event) => {
    const year = Math.floor(this.xScale.invert(d3.pointer(event)[0]));

    const groupedGraphs = this.data.graphs.sort((a, b) => {
      const aPoint = this.findClosestDataPoint(a, year, {
        nextSmallest: true,
      });
      const bPoint = this.findClosestDataPoint(b, year, {
        nextSmallest: true,
      });

      return bPoint[1] - aPoint[1];
    });

    const renderData = (graphData) => {
      const { title, isAvg = false } = graphData.config;
      const consecutiveYears = this.findConsecutiveYears(graphData, year);

      if (
        consecutiveYears.length === 2 &&
        !consecutiveYears.includes(undefined)
      ) {
        const smallerYear = consecutiveYears[0];
        const biggerYear = consecutiveYears[1];
        const difference = biggerYear[1] - smallerYear[1];

        const trend = getTrend(
          biggerYear[1],
          smallerYear[1],
          graphData.config.smallerIsBetter,
        );

        return `
          ${isAvg ? `<span class="chart__tooltip-avg">⌀</span>` : ''}
            <span class="chart__tooltip-title">${title}</span>
            <span class="chart__tooltip-value"> ${biggerYear[1]}${
          graphData.config.unit
            ? `<span class="chart__tooltip-unit">${graphData.config.unit}</span>`
            : ''
        }</span>
            <div class="chart__tooltip-calc">
              <div class="chart__calc-number">
                ${difference >= 0 ? '+' : ''}${roundNumber(difference)}
              </div>
              <span class="chart__calc-trend chart__calc-trend--${trend}"></span>
            </div>`;
      }
      return '';
    };

    const tooltipAlignmentX =
      event.clientX + 120 < window.innerWidth ? 10 : -110;

    this.tooltip
      .html(`<span class="chart__tooltip-year">${year}</span>`)
      .style('display', 'block')
      .style('left', `${event.clientX + tooltipAlignmentX}px`)
      .style('top', `${event.clientY + 10}px`)
      .selectAll()
      .data(groupedGraphs)
      .enter()
      .append('div')
      .attr('class', 'chart__tooltip-item')
      .html(renderData);
  };

  removeTooltip = () => {
    if (this.tooltip) {
      this.tooltip.style('display', 'none');
    }
  };

  drawTrend = (event) => {
    const year = Math.floor(this.xScale.invert(d3.pointer(event)[0]));
    const trendData = this.data.graphs.map((g) => {
      const consecutiveYears = this.findConsecutiveYears(g, year);

      if (
        consecutiveYears.length === 2 &&
        !consecutiveYears.includes(undefined)
      ) {
        const a = consecutiveYears[0];
        const b = consecutiveYears[1];
        const c = [b[0], a[1]];
        const trend = getTrend(b[1], a[1], g.config.smallerIsBetter);

        return {
          ...g,
          trend,
          points: [a, b, c],
        };
      }

      return null;
    });

    if (trendData.length === 0 || !trendData[0]) {
      return;
    }

    this.removeTrend();

    this.triangles = this.svg
      .selectAll('triangleGroup')
      .data(trendData.filter((graph) => !graph.config.isAvg))
      .enter()
      .append('g')
      .attr(
        'class',
        (d) =>
          `${this.classes.triangleGroup} ${this.classes.triangleGroup}--${d.trend}`,
      )
      .selectAll('triangle')
      .data((d) => [d.points])
      .enter()
      .append('path')
      .attr('class', this.classes.triangle)
      .attr('d', (d) => this.line(d));

    d3.selectAll(`.${this.classes.triangleGroup}`).lower();
  };

  removeTrend = () => {
    d3.selectAll(`.${this.classes.triangleGroup}`).remove();
  };

  addLabels() {
    // add label for the average line
    this.lines
      .append('text')
      .attr('class', this.classes.lineLabel)
      .datum((d) => {
        const highestYear = d.points.reduce((acc, item) => {
          if (!acc) {
            return item;
          }
          return item[0] > acc[0] ? item : acc;
        });

        return {
          title: d.config.title,
          value: highestYear,
          isAvg: d.config.isAvg,
        };
      })
      .attr(
        'transform',
        (d) =>
          `translate(${this.xScale(d.value[0]) + 10},${
            this.yScale(d.value[1]) + 5
          })`,
      )
      .attr('x', 5)
      .text((d) => (d.isAvg ? '⌀' : ''));
  }

  findClosestDataPoint = (graph, year, { nextSmallest }) => {
    const { points } = graph;
    let yearPoint = points.find((p) => p[0] === year);

    if (!yearPoint) {
      const smallerYears = points.filter((p) =>
        nextSmallest ? p[0] < year : p[0] > year,
      );

      if (smallerYears.length > 0) {
        yearPoint = smallerYears.reduce((acc, item) => {
          if (!acc) {
            return item;
          }

          if (nextSmallest) {
            return item[0] > acc[0] ? item : acc;
          }

          return item[0] < acc[0] ? item : acc;
        });
      }
    }
    return yearPoint;
  };

  findConsecutiveYears = (graph, year) => {
    const smallerYear = this.findClosestDataPoint(graph, year, {
      nextSmallest: true,
    });
    const biggerYear = this.findClosestDataPoint(graph, year + 1, {
      nextSmallest: false,
    });

    return [smallerYear, biggerYear];
  };
}
