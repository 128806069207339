/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import classNames from 'classnames';

import Icon from '../../0-particles/icon/Icon';

export type Item = {
  name: string;
  id: string;
};

export type AutocompleteProps = {
  label?: string | null;
  placeholder?: string | null;
  menuText?: string;
  name: string;
  prefix?: string | null;
  value?: string | null;
  itemId?: string | null;
  initialEndpoint?: string;
  endpoint: string;
  onChange?: (item: Item | null) => void;
};

const fetchItems = async (endpoint: string): Promise<Array<Item>> => {
  try {
    const apiResult = await fetch(endpoint);
    const newItems = await apiResult.json();

    if (!Array.isArray(newItems?.data)) {
      return [];
    }

    return newItems.data;
  } catch (error) {
    return [];
  }
};

const Autocomplete: React.FC<AutocompleteProps> = ({
  label,
  placeholder,
  initialEndpoint,
  menuText,
  endpoint,
  prefix,
  name,
  value,
  itemId,
  onChange,
}: AutocompleteProps) => {
  const [initialItems, setInitialItems] = useState<Array<Item>>([]);
  const [items, setItems] = useState<Array<Item>>([]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    reset,
    selectedItem,
    inputValue,
    openMenu,
    closeMenu,
  } = useCombobox({
    itemToString: (item) => (item ? item.name : ''),
    items,
    initialSelectedItem:
      value && itemId
        ? {
            id: itemId,
            name: value,
          }
        : null,
    onSelectedItemChange: ({ selectedItem: item }) => {
      setItems(initialItems);
      onChange?.(item || null);
      document.dispatchEvent(new CustomEvent('autocomplete-selected',{ detail: {eventSource: getInputProps().id, item: item}}));
      closeMenu();
    },
  });

  useEffect(() => {
    const init = async () => {
      const newItems = initialEndpoint ? await fetchItems(initialEndpoint) : [];
      setInitialItems(newItems);
    };

    init();
  }, [initialEndpoint]);

  useEffect(() => {
    const fetch = async () => {
      if (inputValue.trim() === '') {
        setItems(initialItems);
      } else {
        const fetchUrl = new URL(endpoint);
        fetchUrl.searchParams.set('q', inputValue);

        const fetchedItems = await fetchItems(fetchUrl.toString());

        if (fetchedItems) {
          setItems(fetchedItems);
        }
      }
    };

    fetch();
  }, [initialItems, endpoint, inputValue]);

  const isListOpen = isOpen && items.length > 0;
  const showInitialItems = initialItems.length > 0 && inputValue.trim() === '';

  return (
    <div
      className={classNames({
        autocomplete: true,
        'autocomplete--open': isListOpen,
        input: true,
      })}
    >
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label {...getLabelProps({ className: 'u-hidden-visually' })}>
          {label}
        </label>
      )}

      <div
        {...getComboboxProps({
          className: 'autocomplete__inner input__inner',
        })}
      >
        <input
          type="hidden"
          name={name}
          value={selectedItem?.id || inputValue}
        />

        {prefix && (
          <span
            className={`autocomplete__prefix autocomplete__prefix--${prefix.toLowerCase()}`}
          >
            {prefix}
          </span>
        )}

        <input
          {...getInputProps({
            className: 'autocomplete__input input__input',
            placeholder: placeholder || undefined,
            onFocus: () => {
              if (showInitialItems) {
                openMenu();
              }
            },
          })}
          readOnly={selectedItem ? 'readonly' : undefined}
        />

        {selectedItem && (
          <button
            className="autocomplete__reset-button"
            type="button"
            onClick={reset}
          >
            <Icon icon="close" title="reset" />
          </button>
        )}
      </div>

      <div
        className={classNames({
          autocomplete__menu: true,
          'autocomplete__menu--open': isListOpen,
        })}
      >
        {isListOpen && showInitialItems && menuText && (
          <div className="autocomplete__menu-text">{menuText}</div>
        )}

        <ul
          {...getMenuProps({
            className: 'autocomplete__list',
          })}
        >
          {isListOpen &&
            items.map((item, index) => {
              const className = classNames({
                'autocomplete__list-item': true,
                'autocomplete__list-item--highlighted':
                  highlightedIndex === index,
              });

              return (
                <li key={item.id} {...getItemProps({ item, index, className })}>
                  {item.name}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default Autocomplete;
