import { on } from 'delegated-events';

on('click', '.js-popup', (event) => {
  const $target = event.target.closest('.js-popup');
  const { href } = $target;
  const { name, title } = $target.dataset;

  if (href && (name || title)) {
    event.preventDefault();
    window.open(href, name || title, 'width=600, height=600');
  }
});
