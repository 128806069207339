import { createElement } from 'react';
import { render } from 'react-dom';
import whenReady from '../../../javascripts/utils/when-ready';
import GoalSlider from './GoalSlider';

whenReady().then(() => {
  const $$goalSlider = document.querySelectorAll('.goal-slider');

  $$goalSlider.forEach(($goalSlider) => {
    const items = [];

    const $silder = $goalSlider.querySelector('.goal-slider__slider');

    $goalSlider.querySelectorAll('.goal-slider__goal').forEach(($goal) => {
      const number = parseInt($goal.dataset.number, 10);
      const $link = $goal.querySelector('.goal-slider__tile');
      const $image = $link.querySelector('img');
      const active = $goal.classList.contains('goal-slider__goal--active');

      items.push({
        number,
        link: $link.href,
        title: $link.title,
        imageUrl: $image.src,
        videoUrl: $link.dataset.video ?? undefined,
        active,
      });
    });

    render(
      createElement(GoalSlider, {
        single: $goalSlider.classList.contains('goal-slider--single'),
        items,
        onChange: (number) => {
          // Update inner class
          // eslint-disable-next-line no-param-reassign
          $goalSlider.className = $goalSlider.className.replace(
            /--sdg-\d+/,
            `--sdg-${number}`,
          );

          // Hide old content
          const $oldContent = $goalSlider.querySelector(
            `.goal-slider__content-item:not([hidden])`,
          );

          if ($oldContent) {
            $oldContent.hidden = true;
          }

          // Set new current content
          const $newContent = $goalSlider.querySelector(
            `.goal-slider__content-item[data-number='${number}']`,
          );

          if ($newContent) {
            $newContent.hidden = false;
          }
        },
      }),
      $silder,
    );
  });
});
