import getVideoId from 'get-video-id';
import whenReady from '../../../javascripts/utils/when-ready';

whenReady().then(() => {
  const $$video = document.querySelectorAll('.video-teaser__play');

  const playVideo = (event) => {
    event.preventDefault();

    const $target = event.currentTarget.closest('.video-teaser__play');
    const { id, service } = getVideoId($target.href);
    const $media = $target.closest('.video-teaser__media');

    if (id && service === 'youtube') {
      // Create wrapper
      const $wrapper = document.createElement('div');
      $wrapper.className = 'video-teaser__video-wrapper';

      // Create iFrame
      const $iframe = document.createElement('iframe');
      $iframe.className = 'video-teaser__iframe';
      $iframe.src = `https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=1`;
      $iframe.allowFullscreen = true;
      $wrapper.appendChild($iframe);

      // Replace with iframe
      if ($media && $media.parentNode) {
        $media.parentNode.replaceChild($wrapper, $media);
      }
    }
  };

  $$video.forEach(($video) => {
    $video.addEventListener('click', playVideo);
  });
});
