export type Trend = 'better' | 'worse' | 'unchanged';

const getTrend = (a: number, b: number, negativeIsBetter: boolean): Trend => {
  if (b < a) {
    return negativeIsBetter ? 'worse' : 'better';
  }

  if (b > a) {
    return negativeIsBetter ? 'better' : 'worse';
  }

  return 'unchanged';
};

export default getTrend;
