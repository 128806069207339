import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import { createFocusTrap } from 'focus-trap';

import invisibleFocus from '../../../javascripts/utils/invisible-focus';

// Navigations overlay
let focusTrap;
on('click', '.header__menu-button', (event) => {
  const $button = event.target.closest('.header__menu-button');
  const $menu = $button.parentNode;
  const $overlay = $menu.querySelector('.header__menu-overlay');

  // Update state
  const isOpen = $button.getAttribute('aria-expanded') !== 'true';
  $button.setAttribute('aria-expanded', isOpen ? 'true' : 'false');

  // On open
  if (isOpen) {
    $overlay.classList.add('header__menu-overlay--open');

    focusTrap = createFocusTrap($menu, {
      onDeactivate() {
        $button.click();
      },
      initialFocus: false,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      preventScroll: false,
    });

    disableBodyScroll($overlay);
    focusTrap.activate();

    const $initialFocus = $overlay.querySelector('.header__navigation-link');
    invisibleFocus($initialFocus);
  } else {
    $overlay.classList.remove('header__menu-overlay--open');

    if (focusTrap) {
      focusTrap.deactivate({
        onDeactivate: false,
      });

      focusTrap = null;
    }

    enableBodyScroll($overlay);
  }
});
