import CustomEvent from 'custom-event';
import { on } from 'delegated-events';
import { invisibleFocus } from '../utils';

const FOCUSABLE_SELECTOR =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const collapse = (event) => {
  // Stop event here
  event.preventDefault();

  // Get state
  const $toggle = event.target.closest('.js-collapse');
  const $content = document.getElementById(
    $toggle.getAttribute('aria-controls'),
  );

  if (event.target.closest('.tooltip')) {
    return;
  }
  // Get current state
  const isOpen = $toggle.getAttribute('aria-expanded') === 'true';

  // Toggle state
  $toggle.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
  $content.hidden = isOpen;

  // Focus on opening
  if (!isOpen) {
    if ($content.matches(FOCUSABLE_SELECTOR)) {
      invisibleFocus($content);
    } else {
      const $$focusable = $content.querySelectorAll(FOCUSABLE_SELECTOR);
      if ($$focusable.length > 0) {
        invisibleFocus($$focusable[0]);
      }
    }
  }

  const collapseEvent = new CustomEvent('collapsed', {
    bubbles: true,
    detail: {
      isOpen: !isOpen,
    },
  });
  $toggle.dispatchEvent(collapseEvent);
};

on('click', '.js-collapse', collapse);
on('keydown', '.js-collapse', (event) => {
  if (event.keyCode === 13) {
    collapse(event);
  }
});
