const whenReady = (): Promise<void> =>
  new Promise((resolve) => {
    if (document.readyState === 'complete') {
      resolve();
    } else {
      // not fired yet, so let's listen for the event
      window.addEventListener('DOMContentLoaded', () => resolve());
    }
  });

export default whenReady;
