window.addEventListener('load',function(e){
	var discoverStageElemsList = document.querySelectorAll('.discover-stage');
	if( discoverStageElemsList.length > 0 ) {
		discoverStageElemsList.forEach(function(discoverStageElem){
			discoverStageElem.querySelector('.discover-stage__form-fields .discover-stage__form-submit .button').disabled = true;
			discoverStageElem.querySelector('.discover-stage__form').addEventListener('submit',function(e){
				if(this.querySelector('input[name="q"]').closest('div.input__inner').querySelector('input.input__input').value.trim() === "") {
					e.preventDefault();
				}
			});
			var inputElem = discoverStageElem.querySelector('input[name="q"]').closest('div.input__inner').querySelector('input.input__input');
			inputElem.addEventListener('input',function(e){
				var submitButtonElem = this.closest('.discover-stage__form-fields').querySelector('.discover-stage__form-submit .button');
				if (this.value.trim() === "") {
					submitButtonElem.disabled = true;
				} else {
					submitButtonElem.disabled = false;
				}
			});
		});
	}
});