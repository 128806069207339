import React from 'react';
import classNames from 'classnames';

export type RadioValue = number | string | undefined;
export type OnRadioChange = (value: RadioValue) => void;

type Props = {
  label: string;
  value: RadioValue;
  name: string;
  onChange: OnRadioChange;
  id: string;
  checked: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
};

const Radio: React.FC<Props> = ({
  label,
  value,
  checked,
  onChange,
  disabled,
  readOnly,
  required,
  name,
  id,
}: Props) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <label
      className={classNames({
        radio: true,
        'has-underline-focus': !disabled && !readOnly,
      })}
      htmlFor={id}
    >
      <input
        className="radio__input"
        id={id}
        name={name}
        value={value}
        checked={checked}
        required={required || false}
        disabled={disabled}
        readOnly={readOnly}
        type="radio"
        onChange={handleChange}
      />

      <div className="radio__icon" />

      <span className="radio__label">
        <span className="u-underline-focus">{label}</span>
      </span>
    </label>
  );
};

export default Radio;
