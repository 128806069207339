/* eslint-disable react/jsx-props-no-spreading */
import React, {
  EventHandler,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import useWindowWidth from '../../../javascripts/hooks/useWindowWidth';
import shiftToEnd from '../../../javascripts/utils/shift-to-end';
import Icon from '../../0-particles/icon/Icon';

type GoalItem = {
  number: number;
  title: string;
  active?: boolean;
  link?: string;
  imageUrl: string;
  videoUrl?: string;
};

type GoalItemProps = GoalItem & {
  onClick?: EventHandler<MouseEvent>;
  playVideo?: boolean;
};

type GoalSliderProps = {
  items: GoalItem[];
  onChange: (selected: number) => void;
  single?: boolean;
};

const GoalItem: React.FC<GoalItemProps> = ({
  onClick,
  playVideo = false,
  videoUrl,
  ...item
}: GoalItemProps) => {
  const [playCount, setPlayCount] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setPlayCount(0);
  }, [videoUrl]);

  useEffect(() => {
    if (videoUrl && playVideo && videoRef.current && playCount < 2) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch();
    }
  }, [videoUrl, playVideo, playCount, videoRef]);

  return (
    <div className="goal-slider__goal">
      {item.link ? (
        <a
          href={item.link}
          className="goal-slider__tile"
          tabIndex={-1}
          title={item.title}
        >
          <img
            className="goal-slider__tile-image"
            src={item.imageUrl}
            width="100"
            height="100"
            alt=""
            role="presentation"
          />

          {videoUrl && (
            <video
              className="goal-slider__tile-video"
              src={videoUrl}
              width="100"
              height="100"
              preload="none"
              muted={true}
              loop={false}
              autoPlay={false}
              ref={videoRef}
              onEnded={() => setPlayCount(playCount + 1)}
              playsInline={true}
            />
          )}
        </a>
      ) : (
        <button
          type="button"
          tabIndex={-1}
          className="goal-slider__tile"
          onClick={onClick}
        >
          <img
            className="goal-slider__tile-image"
            src={item.imageUrl}
            width="100"
            height="100"
            alt=""
            role="presentation"
          />

          {videoUrl && (
            <video
              className="goal-slider__tile-video"
              src={videoUrl}
              width="100"
              height="100"
              preload="none"
              muted={true}
              loop={false}
              autoPlay={false}
              ref={videoRef}
              onEnded={() => setPlayCount(playCount + 1)}
              playsInline={true}
            />
          )}
        </button>
      )}
    </div>
  )
};

const GoalSlider: React.FC<GoalSliderProps> = ({
  items,
  onChange,
  single = false,
}: GoalSliderProps) => {
  const windowWidth = useWindowWidth();
  const [itemsToClone, setItemsToClone] = useState(
    Math.floor(windowWidth / 110 / 2),
  );

  const [currentItem, setCurrentItem] = useState(
    items.findIndex((item) => item.active) || 0,
  );

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    onChange(currentItem + 1);
  }, [currentItem, onChange]);

  useLayoutEffect(() => {
    setItemsToClone(Math.floor(windowWidth / 110 / 2));
  }, [windowWidth]);

  const goToNextGoal = () => setCurrentItem((currentItem + 1) % items.length);
  const goToPrevGoal = () =>
    setCurrentItem(
      currentItem === 0 ? items.length - 1 : (currentItem - 1) % items.length,
    );

  const moveByKeyboard: EventHandler<KeyboardEvent> = (event) => {
    if (event.key === 'ArrowRight') {
      goToNextGoal();
    }

    if (event.key === 'ArrowLeft') {
      goToPrevGoal();
    }
  };

  const currentGoal = items[currentItem];
  const nextGoal =
    currentItem === items.length - 1 ? items[0] : items[currentItem + 1];
  const prevGoal =
    currentItem === 0 ? items[items.length - 1] : items[currentItem - 1];

  return (
    <>
      <div
        className="goal-slider__track"
        tabIndex={!single ? 0 : undefined}
        onKeyDown={!single ? moveByKeyboard : undefined}
        role={!single ? 'tab' : undefined}
        aria-label={!single ? 'Zwischen den SDG-Zielen wechseln' : undefined}
        ref={inViewRef}
      >
        <div className="goal-slider__goals">
          <div className="goal-slider__cloned-items" aria-hidden="true">
            {[...Array(itemsToClone)]
              .map((_, index) => {
                const item = shiftToEnd(items, currentItem).reverse()[
                  index % items.length
                ];

                return (
                  <GoalItem
                    key={`${item.number}-${Math.floor(index / items.length)}`}
                    {...item}
                    active={false}
                    link={single ? item.link : undefined}
                    videoUrl={undefined}
                    onClick={() => setCurrentItem(item.number - 1)}
                  />
                );
              })
              .reverse()}
          </div>

          <div className="goal-slider__current-item">
            <GoalItem {...currentGoal} playVideo={inView} />
          </div>

          <div className="goal-slider__cloned-items" aria-hidden="true">
            {[...Array(itemsToClone)].map((_, index) => {
              const item = items.slice()[
                (currentItem + index + 1) % items.length
              ];

              return (
                <GoalItem
                  key={`${item.number}-${Math.floor(index / items.length)}`}
                  {...item}
                  active={false}
                  link={single ? item.link : undefined}
                  videoUrl={undefined}
                  onClick={() => setCurrentItem(item.number - 1)}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="goal-slider__navigation">
        {single ? (
          <>
            <a
              className="goal-slider__navigation-button"
              title={prevGoal.title}
              href={prevGoal.link}
            >
              <Icon icon="caret-left" />
            </a>

            <a
              className="goal-slider__navigation-button"
              title={nextGoal.title}
              href={nextGoal.link}
            >
              <Icon icon="caret-right" />
            </a>
          </>
        ) : (
          <>
            <button
              type="button"
              className="goal-slider__navigation-button"
              title="Nach links"
              onClick={goToPrevGoal}
            >
              <Icon icon="caret-left" />
            </button>

            <button
              type="button"
              className="goal-slider__navigation-button"
              title="Nach rechts"
              onClick={goToNextGoal}
            >
              <Icon icon="caret-right" />
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default GoalSlider;
