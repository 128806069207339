import React from 'react';
import classNames from 'classnames';
import Icon from '../../0-particles/icon/Icon';

type Props = {
  text: string;
  color: 'transparent' | 'black';
  onClick?: () => void;
  link?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  icon?: string;
  title?: string;
  disabled?: boolean;
};

const Button: React.FC<Props> = ({
  link,
  color,
  type = 'button',
  onClick,
  icon,
  title,
  disabled,
  text,
}: Props) => {
  const Tag = link ? 'a' : 'button';

  return (
    <Tag
      className={classNames({
        button: true,
        [`button--${color}`]: color,
      })}
      type={type}
      title={title}
      disabled={disabled}
      href={link}
      onClick={onClick}
    >
      {icon && (
        <span className="button__icon">
          <Icon icon={icon} />
        </span>
      )}

      <span className="button__text">{text}</span>
    </Tag>
  );
};

export default Button;
