export const roundToXDigits = (value: number, digits = 2): number => {
  return Math.round(value * 10 ** digits) / 10 ** digits;
};

const roundNumber = (value: number): number => {
  if (value >= 100) {
    const multiplicator = 10 ** (Math.floor(Math.log10(value)) - 1);

    return Math.round(value / multiplicator) * multiplicator;
  }

  if (value >= 10 && value < 100) {
    return Math.round(value);
  }

  if (value >= 1 && value < 10) {
    return roundToXDigits(value, 1);
  }

  return roundToXDigits(value, 2);
};

export default roundNumber;
