import React, { useState } from 'react';

import Autocomplete from '../../2-molecules/autocomplete/Autocomplete';

export type InputType = {
  label?: string;
  placeholder?: string;
  value?: string;
  itemId?: string;
  name: string;
  prefix?: string;
};

type CompareFieldProps = {
  endpoint: string;
  initialEndpoint: string;
  menuText: string;
  inputs: [InputType, InputType];
  seperator: string;
};

const appendIdToEndpoint = (endpoint: string, id: string): string => {
  const url = new URL(endpoint);
  url.searchParams.set('id', id);

  return url.toString();
};

const CompareField: React.FC<CompareFieldProps> = ({
  endpoint,
  seperator,
  inputs,
  menuText,
  initialEndpoint,
}: CompareFieldProps) => {
  const [relatedTo, setRelatedTo] = useState<string | null>(
    inputs[0].itemId || null,
  );

  return (
    <>
      <div className="compare-field__field">
        <Autocomplete
          label={inputs[0].label}
          placeholder={inputs[0].placeholder}
          name={inputs[0].name}
          value={inputs[0].value}
          endpoint={endpoint}
          prefix={inputs[0].prefix}
          itemId={inputs[0].itemId}
          onChange={(item) => setRelatedTo(item ? item.id : null)}
        />
      </div>

      <div className="compare-field__seperator">{seperator}</div>

      <div className="compare-field__field">
        <Autocomplete
          label={inputs[1].label}
          placeholder={inputs[1].placeholder}
          name={inputs[1].name}
          value={inputs[1].value}
          endpoint={endpoint}
          prefix={inputs[1].prefix}
          itemId={inputs[1].itemId}
          menuText={relatedTo ? menuText : undefined}
          initialEndpoint={
            relatedTo
              ? appendIdToEndpoint(initialEndpoint, relatedTo)
              : undefined
          }
        />
      </div>
    </>
  );
};

export default CompareField;
