sdgApp.TabHeaderBinder = function() {
    var tabContainers = document.querySelectorAll('.tab-container:not(.binded)');
    for(var i=0; i < tabContainers.length; i++) {
        let tabContainerTabNames = tabContainers[i].querySelectorAll('.tab-container__tab-name')
        for(var j=0; j < tabContainerTabNames.length; j++) {
            tabContainerTabNames[j].addEventListener('click', function(e){
                e.preventDefault();
                let tabContainer = this.closest('.tab-container');
                tabContainer.querySelector('.tab-container__tab-name.active').classList.remove('active');
                this.classList.add('active');
                tabContainer.querySelector('.tab-container__tab-content.active').classList.remove('active');
                tabContainer.querySelectorAll('.tab-container__tab-content')[Array.from(this.parentElement.children).indexOf(this)].classList.add('active');
            });
        }
        tabContainers[i].classList.add('binded');
    }
};

sdgApp.TabHeaderBinder();