import React, { useState } from 'react';
import { OnCheckboxChange } from './Checkbox';

export type CheckboxesState = { [name: string]: boolean };

const useCheckboxes = (): [
  state: CheckboxesState,
  handleChange: OnCheckboxChange,
  setState: React.Dispatch<React.SetStateAction<CheckboxesState>>,
] => {
  const [state, setState] = useState<CheckboxesState>({});

  const handleChange: OnCheckboxChange = (name) => {
    setState((oldState) => {
      if (oldState[name]) {
        const { [name]: remove, ...newState } = oldState;

        return newState;
      }

      return { ...oldState, [name]: true };
    });
  };

  return [state, handleChange, setState];
};

export default useCheckboxes;
