import { createFocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import { createPopper } from '@popperjs/core';

let focusTrap = null;

on('click', '.js-tooltip', (event) => {
  const $target = event.target.closest('.js-tooltip');
  const $icon = $target.querySelector('.tooltip__icon');

  const { dialog } = $target.dataset;

  if (dialog) {
    const $dialog = document.getElementById(dialog);

    if ($dialog && $icon) {
      event.preventDefault();

      $dialog.open = true;
      $dialog.setAttribute('aria-hidden', 'false');

      createPopper($icon, $dialog, {
        placement: 'auto',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      });

      focusTrap = createFocusTrap($dialog, {
        onDeactivate() {
          $dialog.removeAttribute('open');
          $dialog.setAttribute('aria-hidden', 'true');
          $dialog.removeAttribute('style');
        },
        initialFocus: '.tooltip__content',
        clickOutsideDeactivates: true,
        returnFocusOnDeactivate: true,
        preventScroll: false,
      });

      focusTrap.activate();
    }
  }
});

on('click', '.js-tooltip-close', (event) => {
  const $dialog = event.target.closest('.tooltip__dialog');

  $dialog.removeAttribute('open');
  $dialog.setAttribute('aria-hidden', 'true');
  $dialog.removeAttribute('style');

  if (focusTrap) {
    focusTrap.deactivate({
      onDeactivate: false,
    });

    focusTrap = null;
  }
});
