import whenReady from '../../../javascripts/utils/when-ready';

whenReady().then(() => {
  const $$image = document.querySelectorAll('.image__img');
  $$image.forEach(($image) => {
    $image.addEventListener('load', () => {
      $image.classList.add('image__img--loaded');
    });
  });
});
