const wrap = (el: Node): HTMLElement => {
  const $wrapper = document.createElement('div');

  el.parentNode?.insertBefore($wrapper, el);

  $wrapper.appendChild(el);

  return $wrapper;
};

export default wrap;
