import htmx from 'htmx.org';

htmx.on('htmx:afterSwap', (event) => {
  event.target
    .querySelectorAll('noscript.loading-lazy')
    .forEach(($noscript) => {
      while ($noscript.firstChild) {
        $noscript.parentNode.insertBefore($noscript.firstChild, $noscript);
      }
      $noscript.parentNode.removeChild($noscript);
    });
});
