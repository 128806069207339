import React from 'react';
import classNames from 'classnames';

import Icon from '../../0-particles/icon/Icon';

export type OnCheckboxChange = (name: string) => void;

type Props = {
  label: string;
  checked: boolean;
  name: string;
  value: string;
  onChange: OnCheckboxChange;
  id: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
};

const Checkbox: React.FC<Props> = ({
  label,
  checked = false,
  onChange,
  disabled,
  readOnly,
  required = false,
  name,
  id,
  value,
}: Props) => {
  const handleChange = () => {
    onChange(name);
  };

  return (
    <label
      className={classNames({
        checkbox: true,
        'has-underline-focus': !disabled && !readOnly,
      })}
      htmlFor={id}
    >
      <input
        className="checkbox__input"
        id={id}
        name={name}
        value={value}
        checked={checked}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        type="checkbox"
        onChange={handleChange}
      />

      <div className="checkbox__icon">
        <Icon icon="tick" />
      </div>

      <span className="checkbox__label">
        <span className="u-underline-focus">{label}</span>
      </span>
    </label>
  );
};

export default Checkbox;
