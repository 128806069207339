import React from 'react';
import { render } from 'react-dom';

import { whenReady } from '../../../javascripts/utils';

import EnhancedSelect, { Option } from './EnhancedSelect';

whenReady().then(() => {
  const $$enhancedSelect = document.querySelectorAll<HTMLElement>(
    '.js-enhanced-select',
  );

  $$enhancedSelect.forEach(($enhancedSelect) => {
    const $select = $enhancedSelect.querySelector<HTMLSelectElement>('select');

    if (!$select) {
      return;
    }

    const { multiple, name } = $select;

    const $options = $enhancedSelect.querySelectorAll<HTMLOptionElement>(
      'option',
    );

    const options: Option[] = [];
    let label = '';
    let isResetable = false;

    $options.forEach(($option) => {
      if ($option.value === '') {
        isResetable = true;
      }

      if (
        $option.classList.contains('input__select-hint') &&
        $option.textContent
      ) {
        label = $option.textContent;
      } else if ($option.textContent && $option.value) {
        options.push({
          label: $option.textContent,
          value: $option.value,
          selected: $option.selected,
        });
      }
    });

    render(
      <EnhancedSelect
        label={label}
        name={name}
        multi={multiple}
        options={options}
        isResetable={isResetable}
      />,
      $enhancedSelect,
    );
  });
});
