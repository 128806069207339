import whenReady from '../../../javascripts/utils/when-ready';
import LineChart from './LineChart';

whenReady().then(() => {
  const componentStore = [];
  const $$chart = document.querySelectorAll('.chart');

  $$chart.forEach(($chart) => {
    const $dataEl = $chart.querySelector('.chart__data');
    const jsonData = $dataEl ? JSON.parse($dataEl.innerHTML) : {};

    if ($chart.offsetParent !== null && jsonData) {
      componentStore.push(new LineChart($chart, jsonData));
    }
  });
});
