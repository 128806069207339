if(typeof window.sdgApp == "undefined") {
    window.sdgApp = {
        elems: [],
        ids: [],
    };
}
var sdgApp = window.sdgApp;

// ##### HELPER #####

// HAS CLASS
sdgApp.HasClass = function(elem,className) {
    return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
};

// CHECK URL PARAMS
sdgApp.CheckUrlParams = function() {
	var url = window.location.href;
	var queryStart = url.indexOf("?") + 1;
	var queryEnd   = url.indexOf("#") + 1 || url.length + 1;
	var query = url.slice(queryStart, queryEnd - 1);
	var pairs = query.replace(/\+/g, " ").split("&");
	var parms = {};

	if (query == url || query == "") {
		return;
    }

	for(var i=0; i < pairs.length; i++) {
		var nv = pairs[i].split("=", 2);
		var n = decodeURIComponent(nv[0]);
		var v = decodeURIComponent(nv[1]);

		if (!parms.hasOwnProperty(n)) {
			parms[n] = [];
        }
		parms[n].push(nv.length == 2 ? v : null);
    }

	return parms;
}

// GET TEXT LINES
sdgApp.GetTextLines = function(elem){
    var lineHeight = parseInt(window.getComputedStyle(elem, null).getPropertyValue('line-height'),10);
    return elem.offsetHeight / lineHeight;
};

// ID MAKER
sdgApp.IdMaker = function(el, prefix) {
	// if id exists, return it...
	if(typeof el.id !== "undefined" && el.id != "") {
		return el.id;
    }
	// ...otherwise create id based by hash on actual time
	else {
		return prefix + (+new Date).toString(36);
    }
}
sdgApp.GetSetId = function(el, prefix) {
	// if id exists and ...
	if(typeof el.id !== "undefined" && el.id !== "") {
		// ... check if a prefix is given -> ...
		if(typeof prefix !== "undefined" && prefix !== null && prefix != "") {
			// ... check if prefix is already part of id -> otherwise add it and ...
			if(el.id.substr(0,prefix.length) !== prefix) {
				el.id = prefix + el.id;
			}
		}
		// ... return element id
		return el.id;
	}
	// if no id exists -> generate one
	if(typeof el !== "undefined" && el !== null && typeof prefix !== "undefined" && prefix !== null && prefix !== "") {
		var id = sdgApp.IdMaker(el, prefix);
		while(sdgApp.ids.indexOf(id) > -1) {
			id = sdgApp.IdMaker(el, prefix);
		}
		sdgApp.ids.push(id);
		el.id = id;
		return id;
	}
	return null;
}

// GET BASE URL WITH LANGUAGE PATH
sdgApp.GetLangBaseUrl = function() {
	var url = new URL(window.location.href);
    var pathes = url.pathname.split('/');
    pathes = pathes.slice(0,2).join('/');
	return window.location.protocol + "//" + window.location.host + pathes;
}

// ORDER KEYS
sdgApp.OrderKeys = function(o, f) {
	var os=[], ks={}, i;
	for (i in o) {
		os.push([i, o[i]]);
	}
	os.sort(function(a,b){return f(a[1],b[1]);});
	for (i=0; i<os.length; i++) {
		ks[os[i][0]] = os[i][1];
	}
	return ks;
};

// SET CURSOR
sdgApp.SetCursorToStartEnd = function(elem, start) {
	start = typeof start === "undefined" || start === null ? false : start;
	var range = document.createRange();
	var sel = window.getSelection();
	range.selectNodeContents(elem);
	range.collapse(start);
	sel.removeAllRanges();
	sel.addRange(range);
}

sdgApp.ShortenTextcontent = function(elem, maxLength) {
	elem.textContent = elem.textContent.slice(0,-(elem.textContent.length - maxLength));
	sdgApp.SetCursorToEnd(elem);
}

// STRIP HTML
sdgApp.StripHTML = function(html) {
	let tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
}

// STRING TO HTML
sdgApp.StringToHTML = function(str) {
	var parser = new DOMParser();
	var doc = parser.parseFromString(str,'text/html');
	var childNodes = doc.body.childNodes;
	return childNodes.length == 1 ? childNodes[0] : childNodes;
}

// ##### COMMON #####
sdgApp.ShowFormRowError = function(formRow, errorMessage, useDatasetError) {
    if (typeof formRow !== "undefined" && formRow !== null && sdgApp.HasClass(formRow, 'form-row')) {
        var error = (typeof errorMessage !== "undefined" && errorMessage !== null && errorMessage != "")
					? errorMessage
					: (
						(typeof useDatasetError !== "undefined" && useDatasetError !== null && useDatasetError !== "")
						? (typeof formRow.querySelector('.input__input').dataset[useDatasetError] !== "undefined" && formRow.querySelector('.input__input').dataset[useDatasetError] !== null && formRow.querySelector('.input__input').dataset[useDatasetError] !== "")
							? formRow.querySelector('.input__input').dataset[useDatasetError]
							: ""
						: (typeof formRow.dataset.errorMessage !== "undefined" && formRow.dataset.errorMessage !== null && formRow.dataset.errorMessage != "")
							? formRow.dataset.errorMessage
							: (typeof formRow.querySelector('.input__input').dataset.errorMessage != "undefined" && formRow.querySelector('.input__input').dataset.errorMessage != null && formRow.querySelector('.input__input').dataset.errorMessage != "")
								? formRow.querySelector('.input__input').dataset.errorMessage
								: ""
					);
        if (error != "") {
            var formRowError = document.createElement('div');
            formRowError.classList.add('form-row__error');
            formRowError.innerHTML = error;
            formRow.appendChild(formRowError);
            formRow.classList.add('error');
        }
    }
}
sdgApp.RemoveFormRowError = function(formRow) {
	if (typeof formRow !== "undefined" && formRow !== null && sdgApp.HasClass(formRow, 'form-row') && formRow.querySelector('.form-row__error')) {
		formRow.querySelector('.form-row__error').remove();
		formRow.classList.remove('error');
	}
}