import React from 'react';
import classNames from 'classnames';

type Props = {
  icon: string;
  id?: string;
  viewBox?: string;
  title?: string;
  description?: string;
};

const Icon: React.FC<Props> = ({
  icon,
  id,
  viewBox,
  title,
  description,
}: Props) => {
  return (
    <svg
      id={id}
      className={classNames(['icon', `icon--${icon}`])}
      viewBox={viewBox || '0 0 200 200'}
      role={title || description ? 'img' : 'presentation'}
      focusable="false"
      aria-labelledby={classNames({
        [`${id}-title`]: title,
        [`${id}-description`]: description,
      })}
    >
      {title && <title id={`${id}-title`}>{title}</title>}
      {description && <desc id={`${id}-description`}>{description}</desc>}
      <use xlinkHref={`${window.globals.iconPath}#icon-${icon}`} />
    </svg>
  );
};

export default Icon;
