var sdgExportButton = document.getElementById('sdg__export-dialog');
var exportDialogElem = document.querySelector('.exportdialog');
var exportPDFButton = exportDialogElem ? exportDialogElem.querySelector('.exportdialog__exportpdf .button') : null;

var ExportDialogShowFile = function(blob){
	var newBlob = new Blob([blob], {type: "application/pdf"})

	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(newBlob);
		return;
	} 

	const data = window.URL.createObjectURL(newBlob);
	var link = document.createElement('a');
	link.href = data;
	link.download=exportDialogElem.dataset.filetitle + ".pdf";
	link.click();
	setTimeout(function(){
		window.URL.revokeObjectURL(data);
		exportDialogElem.classList.remove('loaderopen');
		exportPDFButton.blur();
		exportDialogElem.classList.remove('show');
	}, 100);
}

var UpdatePDFQueryString = function() {
	var queryString = "";
	var compareFieldsHidden = exportDialogElem.querySelectorAll('.compare-field__field input[name="q[]"]');
	if(compareFieldsHidden[0].value == "" && compareFieldsHidden[1].value == "") {
		exportPDFButton.setAttribute('disabled','disabled');
	}
	else {
		exportPDFButton.removeAttribute('disabled');
		queryString = compareFieldsHidden[0].value == "" || compareFieldsHidden[1].value == "" ? 'q=' + (compareFieldsHidden[0].value != "" ? compareFieldsHidden[0].value : compareFieldsHidden[1].value) : 'a=' + compareFieldsHidden[0].value + '&b=' + compareFieldsHidden[1].value;

		exportDialogElem.querySelectorAll('.exportdialog__goals input[type="checkbox"]').forEach(function(goalCheckbox){
			if(goalCheckbox.checked) {
				queryString += '&' + goalCheckbox.value + '=' + goalCheckbox.value + "&goals%5B%5D=" + goalCheckbox.value;
			}
		});
		//queryString += '&longTermComparison=' + (document.getElementById('printoverlay_long-term-comparison').checked ? '1' : '0');
		queryString += '&longTermComparison=1';

		if(document.getElementById('printoverlay_show-average').checked) {
			queryString += '&showAverage=1';
		} else {
			queryString += '&showAverage=0'
		}

		/*
		var periodFrom = document.getElementsByName('printoverlaySelectFrom')[0].value;
		var periodTo = document.getElementsByName('printoverlaySelectTo')[0].value;
		if(periodFrom != "") {
			queryString += '&start=' + periodFrom;
		}
		if(periodTo != "") {
			queryString += '&end=' + periodTo;
		}
		*/

		exportPDFButton.href = exportPDFButton.href.substr(0,exportPDFButton.href.indexOf('?')) + '?' + queryString;
	}
}

if(sdgExportButton && exportDialogElem) {
	exportDialogElem.style.display = 'block';
	exportDialogElem.querySelector('.exportdialog__closer').addEventListener('click',function(e){
		e.preventDefault();
		this.blur();
		this.closest('.exportdialog').classList.remove('show');
	});

	exportDialogElem.querySelectorAll('select').forEach(function(selectElem){
		selectElem.querySelectorAll('option').forEach(function(optionElem){
			if(optionElem.value == "") {
				optionElem.disabled = true;
			}
		});
		selectElem.addEventListener('change',function(e){
			if(this.name == "printoverlaySelectFrom") {
				var self = this;
				var selectToElem = document.getElementsByName('printoverlaySelectTo')[0];
				var i = 0;
				selectToElem.querySelectorAll('option').forEach(function(optionsElem){
					if(i > 0) {
						optionsElem.disabled = false;
					}
					if(parseInt(optionsElem.value,10) < parseInt(self.value,10)) {
						if(optionsElem.selected) {
							optionsElem.selected = false;
							optionsElem.nextSibling.selected = true;
						}
						optionsElem.disabled = true;
					}
					i++;
				});
				if(parseInt(selectToElem.value) < parseInt(self.value,10)) {
					selectToElem.value = selectToElem.options[self.selectedIndex].value;
				}
			}
			UpdatePDFQueryString();
		});
	});
	exportDialogElem.querySelector('.exportdialog__resetgoals').addEventListener('click',function(e){
		e.preventDefault();
		this.blur();
		this.closest('.exportdialog__goals').querySelectorAll('.checkbox').forEach(function(checkboxElem){
			checkboxElem.querySelector('input[type=checkbox]').checked = false;
		});
		UpdatePDFQueryString();
	});
	exportDialogElem.querySelector('.exportdialog__setgoals').addEventListener('click',function(e){
		e.preventDefault();
		this.blur();
		this.closest('.exportdialog__goals').querySelectorAll('.checkbox').forEach(function(checkboxElem){
			checkboxElem.querySelector('input[type=checkbox]').checked = true;
		});
		UpdatePDFQueryString();
	});

	exportDialogElem.querySelectorAll('.exportdialog__goals input[type="checkbox"], .exportdialog input[type="radio"], #printoverlay_show-average').forEach(function(inputElem) {
		inputElem.addEventListener('change', function(e){
			UpdatePDFQueryString();
		});
	});
	

	exportPDFButton.addEventListener('click',function(e){
		e.preventDefault();
		UpdatePDFQueryString();
		if(!this.hasAttribute("disabled")) {	
			exportDialogElem.classList.add('loaderopen');
			fetch(this.href, {})
			.then(r => r.blob())
			.then(ExportDialogShowFile);
		}
	});

	sdgExportButton.addEventListener('click',function(e){
		e.preventDefault();
		exportDialogElem.querySelectorAll('.exportdialog .compare-field__field .autocomplete__input').forEach(function(autoInput){
			autoInput.addEventListener('blur', function(e){
				window.clearInterval(window.exportDialogBlurDelay);
				window.exportDialogBlurDelay = window.setInterval(function(){
					window.clearInterval(window.exportDialogBlurDelay);
					UpdatePDFQueryString();
				},200);
			});
			autoInput.addEventListener('focus', function(e){
				UpdatePDFQueryString();
			});
		});
		if(exportDialogElem.querySelector('.exportdialog .compare-field__field .autocomplete__reset-button')) {
			exportDialogElem.querySelectorAll('.exportdialog .compare-field__field .autocomplete__reset-button').forEach(function(resetButton){
				resetButton.addEventListener('click',function(e){
					window.clearInterval(window.exportDialogResetDelay);
					window.exportDialogResetDelay = window.setInterval(function(){
						window.clearInterval(window.exportDialogResetDelay);
						UpdatePDFQueryString();
					},200);
				});
			});
		}
		exportDialogElem.classList.add('show');
	});
} else {
	if(sdgExportButton) {
		sdgExportButton.remove();
	}
}