const { thresholdScott } = require("d3");

sdgApp.InputBinder = function() {
    var fileInputs = document.querySelectorAll('.input__file:not(.binded) input[type=file]');
    for(var i = 0; i< fileInputs.length; i++) {
        fileInputs[i].addEventListener('input',function(e){
            if(sdgApp.HasClass(this.closest('.input').parentNode, 'form-row')) {
                sdgApp.RemoveFormRowError(this.closest('.form-row'));
            }
            if(this.value != ""){
                if(this.closest('.input').querySelector('input[type=hidden]')) {
                    var maxFileSize = parseInt(this.closest('.input').querySelector('input[type=hidden]').value,10);
                }
                var fileNames = [];
                for(var j = 0; j < this.files.length; j++) {
                    var file = this.files[j];
                    if(maxFileSize){
                        if(file.size <= maxFileSize){
                            fileNames.push(file.name);
                        } else {
                            sdgApp.InputEmptyFileInput(this);
                            if(sdgApp.HasClass(this.closest('.input').parentNode, 'form-row')) {
                                sdgApp.ShowFormRowError(this.closest('.form-row'));
                            }
                            
                            break;
                        }
                    } else {
                        fileNames.push(file.name);
                    }
                }
                this.closest('.input__file').querySelector('.file-input__file-names').innerHTML = fileNames.join(', ');
                if(this.value !== "") {
                    this.closest('.input__file').classList.add('filled');
                    this.dispatchEvent(new CustomEvent('files-added',{bubbles: true}));
                }
            } else {
                this.closest('.input__file').classList.remove('filled');
            }
        });
        fileInputs[i].closest('.input__file').querySelector('.button[href="#delete-files"]').addEventListener('click',function(e){
            e.preventDefault();
            sdgApp.InputEmptyFileInput(this);
            this.blur();
        });
        fileInputs[i].closest('.input__file').classList.add('binded');
    }
}

sdgApp.InputEmptyFileInput = function(fileInputElem) {
    fileInputElem.closest('.input__file').querySelector('input[type=file]').value = "";
    fileInputElem.closest('.input__file').querySelector('input[type=file]').dispatchEvent(new Event('input', {bubbles:true}));
    fileInputElem.closest('.input__file').querySelector('input[type=file]').dispatchEvent(new CustomEvent('files-removed',{bubbles: true}));
}

sdgApp.InputBinder();