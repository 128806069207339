import whenReady from '../../../javascripts/utils/when-ready';
import LineChart from '../../2-molecules/chart/LineChart';

whenReady().then(() => {
  const componentStore = [];
  const $$card = document.querySelectorAll('.indicator-card__item');
  const $$button = document.querySelectorAll(
    '.indicator-card__secondary-button',
  );

  $$card.forEach(($card) => {
    $card.addEventListener('collapsed', () => {
      const $chart = $card.querySelector('.chart');

      if ($chart) {
        if ($chart.querySelector('svg')) {
          return;
        }

        const $dataEl = $chart.querySelector('.chart__data');
        const jsonData = $dataEl ? JSON.parse($dataEl.innerHTML) : {};

        if (jsonData) {
          componentStore.push(new LineChart($chart, jsonData));
        }
      }
    });
  });

  $$button.forEach(($button) => {
    const closeText = $button.getAttribute('data-text-close');
    const openText = $button.querySelector('.button__text').innerHTML;

    $button.addEventListener('click', (event) => {
      const $toggle = event.target.closest('.js-collapse');
      if ($toggle) {
        const isOpen = $toggle.getAttribute('aria-expanded') === 'true';
        $toggle.querySelector('.button__text').innerHTML = isOpen
          ? openText
          : closeText;
      }
    });
  });
});
