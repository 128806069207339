import { createElement } from 'react';
import { render } from 'react-dom';
import whenReady from '../../../javascripts/utils/when-ready';
import CompareField, { InputType } from './CompareField';

whenReady().then(() => {
  const $$compareField = document.querySelectorAll<HTMLElement>(
    '.compare-field',
  );

  $$compareField.forEach(($compareField) => {
    const { endpoint, initialEndpoint, menuText } = $compareField.dataset;

    if (!endpoint || !initialEndpoint || !menuText) {
      return;
    }

    const $seperator = $compareField.querySelector<HTMLElement>(
      '.compare-field__seperator',
    );

    const $$input = $compareField.querySelectorAll<HTMLInputElement>(
      '.input__input',
    );

    if ($$input.length !== 2 || !$seperator) {
      return;
    }

    const inputs: [InputType, InputType] = [
      {
        label:
          $$input[0].closest('.input')?.querySelector<HTMLLabelElement>('label')
            ?.textContent || undefined,
        placeholder: $$input[0].placeholder || undefined,
        name: $$input[0].name,
        value: $$input[0].value,
        itemId: $$input[0].closest<HTMLElement>('.input')?.dataset.itemId,
        prefix: $$input[0].closest<HTMLElement>('.input')?.dataset.prefix,
      },
      {
        label:
          $$input[1].closest('.input')?.querySelector<HTMLLabelElement>('label')
            ?.textContent || undefined,
        placeholder: $$input[0].placeholder || undefined,
        name: $$input[1].name,
        value: $$input[1].value,
        itemId: $$input[1].closest<HTMLElement>('.input')?.dataset.itemId,
        prefix: $$input[1].closest<HTMLElement>('.input')?.dataset.prefix,
      },
    ];

    render(
      createElement(CompareField, {
        endpoint,
        inputs,
        seperator: $seperator.textContent || '',
        initialEndpoint,
        menuText,
      }),
      $compareField,
    );
  });
});
