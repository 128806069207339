import { on } from 'delegated-events';

on('click', '.js-share', (event) => {
  event.preventDefault();
  const $target = event.currentTarget;

  if (navigator.share) {
    navigator
      .share({
        title: $target.getAttribute('data-title'),
        text: $target.getAttribute('data-text'),
        url: window.location.href,
      })
      .then(() => {
        console.log('Thanks for sharing!');
      })
      .catch((err) => {
        console.log(`Couldn't share because of`, err.message);
      });
  } else {
    console.log('web share not supported');
  }
});
